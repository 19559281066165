<!--
 * @Description: 导入中心
 * @Author: WuPuyi
 * @Date: 2021-03-19 11:58:21
 * @LastEditors: LiangYiNing
 * @LastEditTime: 2022-10-14 16:30:01
-->
<template>
  <div class="upload-center content-view-wrapper pd-10">
    <div
      class="content-view"
      v-loading="loading"
      ref="sectionWrapper"
      :element-loading-text="pageLoadingText"
    >
      <div class="edit-wrapper">
        <div v-if="moduleKey" class="edit-wrapper__body">
          <div class="body-content">
            <el-row class="edit-title-div fixed-section">
              <el-col :span="14">
                <div class="edit-title">
                  {{ initInfo.titleName || "导入信息" }}
                  <span class="title-tip">
                    <i class="iconfont e6-icon-abnormal_line"></i>
                    请务必下载模板填写，以便成功导入信息
                  </span>
                </div>
                <!--上传组件 start-->
                <div class="uploader-wrapper">
                  <el-button
                    class="download-btn"
                    type="primary"
                    plain
                    @click="handleDownloadTemplate"
                  >
                    下载模板
                  </el-button>
                  <!-- 
                    :headers="{
                      Origin: host,
                      token: token
                    }"
                   -->
                  <el-upload
                    class="uploader"
                    ref="uploader"
                    :file-list="fileList"
                    accept=".xls,.xlsx"
                    :multiple="false"
                    name="multipartFile"
                    :action="uploadAction"
                    :before-upload="beforeUpload"
                    :on-change="uploadOnChange"
                    :on-progress="uploadProgress"
                    :http-request="analysisXlsx"
                    :on-success="uploadSuccess"
                    :on-error="uploadError"
                  >
                    <el-button type="primary">
                      点击上传
                    </el-button>
                  </el-upload>
                  <span class="tips">只能上传.xls/.xlsx文件</span>
                  <span
                    class="tips_warning"
                    v-if="specialKey.includes(moduleKey) && fileIsParsed"
                  >
                    {{ uploadTip.parseFileWarning }}
                  </span>
                </div>
                <!--上传组件 end-->
              </el-col>
              <el-col :span="10">
                <div class="upload-tip" v-if="specialKey.includes(moduleKey)">
                  <p class="warning">
                    导入功能可支持【批量修改信息】：先导出已有数据，编辑后重新上传，现在就去
                    <el-link
                      type="primary"
                      :underline="false"
                      @click="handleGoBack"
                    >
                      导出
                    </el-link>
                    。
                  </p>
                  <p>说明：</p>
                  <p>1、表格的内容格式、顺序不可进行变更，以防导入失败。</p>
                </div>
              </el-col>
            </el-row>

            <!--解析结果表格 start-->
            <div class="table-wrapper">
              <el-table
                v-if="analysisLists.length > 0"
                :data="tableData"
                ref="analysisTable"
                border
                stripe
                highlight-current-row
                :rowHeight="37"
                :height="resizeViewHeight - 55 + 'px'"
                useVirtual
                style="width: 100%"
                @select="handleSelectionChanged"
                @select-all="handleAllSelectChanged"
                :row-key="
                  row => {
                    return row.excelRowId;
                  }
                "
              >
                <el-table-column
                  type="selection"
                  reserve-selection
                  fixed
                  align="center"
                  width="50"
                  :selectable="isRowSelectable"
                ></el-table-column>
                <el-table-column label="序号" fixed align="center" width="50">
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.excelRowId }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="isImported"
                  label="导入状态"
                  align="center"
                  width="90"
                >
                  <template slot-scope="{ row }">
                    <span
                      v-if="row.code === 'OK'"
                      class="import-status success"
                    >
                      成功
                    </span>
                    <span
                      v-else-if="row.code === -1"
                      class="import-status pending"
                    >
                      上传中...
                    </span>
                    <el-popover
                      v-else-if="row.code === 0"
                      placement="top"
                      trigger="hover"
                    >
                      <p class="fail-reason-content">{{ row.message }}</p>
                      <span slot="reference" class="import-status fail">
                        失败
                      </span>
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="isImported"
                  label="导入失败原因"
                  prop="message"
                  header-align="center"
                  width="130"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  v-for="(col, idx) in columns"
                  :label="col"
                  :prop="col"
                  min-width="110"
                  header-align="center"
                  show-overflow-tooltip
                  :key="`${col}-${idx}`"
                ></el-table-column>
                <el-table-column
                  fixed="right"
                  align="center"
                  label="操作"
                  width="100"
                  class-name="td-operate"
                >
                  <template
                    slot-scope="{ row, $index }"
                    v-if="isRowSelectable(row)"
                  >
                    <el-button
                      type="text"
                      @click="handleEditResult(row, $index)"
                    >
                      修改
                    </el-button>
                    <el-button type="text" @click="handleDeleteResult(row)">
                      删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!--解析结果表格 end-->
            <!-- 分页 start -->
            <section
              class="pagination-wrapper fixed-section"
              v-if="analysisLists.length > 0"
            >
              <el-pagination
                :page-size.sync="searchForm.pageSize"
                :current-page.sync="searchForm.pageIndex"
                :page-sizes="pageSizes"
                :layout="layout"
                :total="analysisLists.length"
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              ></el-pagination>
            </section>
          </div>
        </div>
        <div v-if="moduleKey" class="edit-wrapper__footer  fixed-section">
          <el-button class="cancel" @click="handleGoBack">取消</el-button>
          <el-button type="primary" @click="handleImport">导入</el-button>
        </div>
      </div>

      <el-dialog
        v-dialogDrag
        title="修改"
        :visible.sync="editVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="600px"
        custom-class="edit-dialog"
      >
        <div class="edit-form-wrapper">
          <el-form ref="editAnalysisForm" label-width="120px">
            <template v-for="(item, idx) in curEditList">
              <el-form-item
                v-if="!notShowKeys.includes(item[0])"
                :label="item[0]"
                :key="idx"
              >
                <el-input v-model.trim="item[1]"></el-input>
              </el-form-item>
            </template>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button class="cancel" @click="editVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="handleSaveEditAnalysis">
            <!--  v-has="105204" -->
            确认修改
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
/* **************************************************************
 * 导入示例
 * // 对应信息展示
 * excelRowId
 * this.routerPush({
 *   // 跳转此页面示例
 *   name: "uploadCenter",
 *   params: {
 *     // 各个页面约定key
 *     moduleKey: "driverkey",
 *     // 联动勾选导入页入参(参数具有唯一性，模板必含该字段)，其他页面不传
 *     judgeKey: encodeURIComponent("订单号"),
 *     //来源路由名称
 *     origin: "driversManage",
 *     //json字符串，动态导入模板引入参数
 *     dynamicTemplate: "{"fileName":"xxx","sceneGroupIdSet":[1,2,3]}"
 *     // 跳转导入中心需刷新页面
 *     refresh: true
 *   }
 * });
 *
 * 导入背景：
 *  1、浏览器限制http的并发请求，所以前端限制最多并发的http请求
 *  2、因后台写库限制，所以前端限制1个http请求最多发N条数据
 *  3、因业务场景限制，当带上judgeKey时，相同的judgeKey的数据必须同时发，不受第二点的限制
 *  4、因业务场景限制，当带上syncKey时，相同syncKey的数据必须异步发送，前一条发送完成（http请求返回）后一条才能发送
 *  5、judgeKey与syncKey都存在时以judgeKey为主
 *
 * 所以：
 * 1、用maxReq限制并发的http请求数，当maxReq = 1时，则满足了syncKey的需求
 * 2、用maxDataSize（query传入）限制一个http请求的数据量，当传入judgeKey时 maxDataSize = 1e6（导入中心Excel限制1万，所以此值相当于 Infinity）
 * **************************************************************/

import XLSX from "xlsx";
// mixins
import listPage from "@/mixins/list-page";
import base from "@/mixins/base";

// utils
import {
  printError,
  exportXlsx,
  getKeyValueFromRouterParams
} from "@/utils/util";

// interface
import {
  getUploadCenterInitInfo,
  importAnalysisResult,
  getExportTemplate
} from "@/api";

// constant variables
// 特殊提示提示页面
const SPECIAL_KEY = [];

import { e3Api } from "@/config/api";
const { E3_BASE_FILE_UPLOAD } = e3Api;

export default {
  name: "uploadCenter",
  mixins: [base, listPage],
  data() {
    return {
      excelRowIdMap: new Map(),
      specialKey: Object.freeze(SPECIAL_KEY),
      pageLoadingText: "",
      searchForm: { pageSize: 100, pageIndex: 1 },
      fileList: [], // 文件列表
      initInfo: {}, // 初始数据
      columns: [], // 解析结果表格列数据
      analysisLists: [], // 上传文件解析结果列表
      allChecked: false, //是否全选
      fileIsParsed: false, // 文件是否已被解析
      isImported: false, // 是否已经导入(是否展示导入状态/失败原因列)
      // 当前导入的数据下标索引
      currentRequestIndex: 0,
      selections: [], // 选中的要导入的解析结果数据
      editVisible: false, // 修改解析结果弹框
      notShowKeys: ["excelRowId", "code", "message"], // 编辑弹框不展示的字段
      curEditList: [], // 当前修改的行数据（数组）
      maxRequest: 0, // 最大同时上传个数
      curEditIndex: null, // 当前修改的行索引
      // 最大的http并发请求
      maxReq: 10
    };
  },

  computed: {
    // 前端分页逻辑
    tableData() {
      let { pageIndex, pageSize } = this.searchForm;
      return this.analysisLists.slice(
        pageSize * (pageIndex - 1),
        pageSize * pageIndex
      );
    },
    // 保存导入数据时参数是否需要套一层对象 true时参数格式: {list: []} false时参数格式: []
    isNeedObjectParam: vm =>
      getKeyValueFromRouterParams(vm, "isNeedObjectParam") || false,
    // 动态下载模板引入参数
    dynamicTemplate: vm =>
      getKeyValueFromRouterParams(vm, "dynamicTemplate") ?? "",
    // 源页面key
    moduleKey: vm => getKeyValueFromRouterParams(vm, "moduleKey"),
    // 服务器名/接口前缀
    serverName: vm =>
      getKeyValueFromRouterParams(vm, "serverName") || E3_BASE_FILE_UPLOAD,
    // 联动勾选关键字
    judgeKey: vm =>
      decodeURIComponent(getKeyValueFromRouterParams(vm, "judgeKey") || ""),
    // 同步上传字段
    syncKey: vm => {
      const syncKeyStr = getKeyValueFromRouterParams(vm, "syncKey") || "";
      return syncKeyStr ? syncKeyStr.split(",") : [];
    },
    // 最大单个http的数据量
    maxDataSize: vm =>
      parseInt(getKeyValueFromRouterParams(vm, "maxDataSize")) || 500,
    // 源页面完整url - "取消"按钮跳转使用
    originRouteName: vm => getKeyValueFromRouterParams(vm, "origin"),
    // 提示信息
    uploadTip() {
      switch (this.moduleKey) {
        // TODO: 车辆档案moduleKey不是此值时请更换
        case "vehicleKey":
        case "driverkey":
          return {
            parseFileWarning: "",
            notEditKey: ""
          };
        default:
          return {
            parseFileWarning: "",
            notEditKey: ""
          };
      }
    },
    // Token
    token: () => Cookies.get("E3RequestToken"),
    // host
    host: () => `${window.location.protocol}//${window.location.host}`,
    // 导入接口路径
    importUrl: vm => `${vm.initInfo.saveUrl}` || "",
    // 文件上传路径
    uploadAction: () =>
      // "https://e3aly.e6gpshk.com/api/E3-BASE-FILE-UPLOAD/file/parse",
      `${process.env.VUE_APP_HOST_API ||
        ""}/api/E3-BASE-FILE-UPLOAD/file/parse`,
    // 公司id，用于带机设备导入
    corpId: vm => getKeyValueFromRouterParams(vm, "corpId")
  },

  created() {
    this.moduleKey && this.getInitInfo(); // 获取初始数据
  },

  activated() {
    if (this.$route.params.refresh) {
      this.refreshData();
    }
  },

  methods: {
    // 重置页面数据
    refreshData() {
      this.columns = [];
      this.fileList = [];
      this.initInfo = {};
      this.columns = [];
      this.analysisLists = [];
      this.fileIsParsed = false;
      this.isImported = false;
      this.selections = [];
      this.editVisible = false;
      this.curEditList = [];
      this.curEditIndex = null;
      this.moduleKey && this.getInitInfo(); // 获取初始数据
    },
    // 获取模块名称/模板下载路径/导入时业务方接口
    async getInitInfo() {
      try {
        this.pageLoadingText = "数据正在加载中...";
        this.loading = true;
        let res = await getUploadCenterInitInfo({
          templateKey: this.moduleKey
        });
        const initInfo = this.getFreezeResponse(res, {
          path: "data",
          defaultVal: {},
          freeze: false
        });
        this.initInfo = initInfo || {};
      } catch (err) {
        printError(err);
      } finally {
        this.loading = false;
      }
    },
    // 批量导入选中数据
    async batchImportAnalysisResult(rowsData) {
      try {
        this.pageLoadingText = "数据正在导入中...";
        this.isImported = true; // 展示导入状态/失败原因列
        // 设置数据状态
        rowsData.forEach(v => {
          this.$set(v, "code", -1);
          this.$set(v, "message", "");
        });

        let data = null;
        // 格式化数据，对数据进行分组
        if (this.judgeKey) {
          data = this.formatImportDataByJudgeKey(rowsData);
          // 直接发送分组数据
          this.submitDataGroup(data);
        } else if (this.syncKey.length) {
          data = this.formatImportDataBySyncKey(rowsData);
          this.submitDataByMaxDataSize(data);
        } else {
          // 只要满足maxDataSize限制即可
          this.submitDataByMaxDataSize([rowsData]);
        }
        // 对数据分组后进行发送
      } catch (err) {
        printError(err);
      }
    },
    // 提交数据，先限制每一个HTTP请求的最大数据量
    async submitDataByMaxDataSize(dataArr) {
      if (!Array.isArray(dataArr) || !dataArr.length) return;
      const { maxDataSize, submitDataGroup } = this;
      while (dataArr.length) {
        // 拿出一组数据
        const group = dataArr.pop();
        // 将这组数据再分组，满足最大dataSize的限制
        const groupCollection = [];
        while (group.length) groupCollection.push(group.splice(0, maxDataSize));
        // 发送分组数据
        await submitDataGroup(groupCollection);
      }
    },
    // 提交分组数据，在这一步对http的并发进行限制
    async submitDataGroup(dataArr) {
      if (!Array.isArray(dataArr) || !dataArr.length) return;

      try {
        const { maxReq, submitData } = this;
        while (dataArr.length) {
          const group = dataArr.splice(0, maxReq);
          // 使用await暂停while循环，达到并发分组的目的
          await Promise.all(group.map(v => submitData(v)));
        }
      } catch (e) {
        printError(e);
      }
    },
    // 提交数据
    async submitData(data) {
      if (!Array.isArray(data) || !data.length) return;
      try {
        let res = null;
        // 带机入网导入的情况下，需要处理导入参数
        if (this.moduleKey === "machineIntoTheNet") {
          res = await importAnalysisResult(
            `${this.serverName}/${this.importUrl}`,
            {
              corpId: this.corpId,
              networkAccessInformation: data
            }
          );
        } else {
          let param = this.isNeedObjectParam ? { list: data } : data;
          res = await importAnalysisResult(
            `${this.serverName}/${this.importUrl}`,
            param
          );
        }
        const { code, data: result, message } = res;
        if (code === "OK") {
          result.forEach(rowRes => {
            // 用返回数据中的excelRowId字段查找表格数据analysisLists中对应行索引idx
            // const { data: { excelRowId = "" } = {} } = rowRes;
            const excelRowId = rowRes.data
              ? rowRes.data.excelRowId
              : rowRes.excelRowId;
            if (!excelRowId) return;
            const dataItem = this.excelRowIdMap.get(excelRowId);
            if (!dataItem) return;
            // 这里统一下code 失败的都为0
            this.$set(dataItem, "code", rowRes.code === "OK" ? "OK" : 0);
            this.$set(dataItem, "message", rowRes.message || rowRes.msg);
          });
        } else {
          // 设置数据状态为失败
          data.forEach(v => {
            this.$set(v, "code", 0);
            this.$set(v, "message", message);
          });
        }
      } catch (err) {
        printError(err);
      }
    },
    // 通过judgeKey格式化上传数据
    formatImportDataByJudgeKey(data) {
      const { judgeKey } = this;
      // 分组数据，将相同key的数据分到一组
      let groupCollection = new Map();
      data.forEach(row => {
        // 找出相同的数据组
        const group = groupCollection.get(row[judgeKey]);
        // 不存在则添加，否则直接推入
        group ? group.push(row) : groupCollection.set(row[judgeKey], [row]);
      });

      // 将map还原为二维数组
      return [...groupCollection.values()];
    },
    // 通过syncKey对数据分组
    formatImportDataBySyncKey(data) {
      // 因为存在一边遍历一边删除的操作，所以使用set结构
      const dataSet = new Set(data);
      const { syncKey } = this;
      const result = [];

      while (dataSet.size) {
        // 分组数据
        const arr = [];
        // 缓存keys，便于快速查重
        const keySet = {};
        syncKey.forEach(v => (keySet[v] = new Set()));

        dataSet.forEach(item => {
          const repeat = syncKey.some(v => keySet[v].has(item[v]));

          // 如果有重复的key，则循化下一个
          if (repeat) return;

          arr.push(item);
          dataSet.delete(item);
          // 更新key集合，防止重复
          syncKey.forEach(v => keySet[v].add(item[v]));
        });

        // 保存分组
        result.push(arr);
      }
      return result;
    },
    // 修改解析结果
    handleEditResult(row, index) {
      let curEditData = _.cloneDeep(row);
      this.curEditList = [];
      this.curEditList.push(["excelRowId", curEditData.excelRowId]);
      if (curEditData.code || curEditData.code === 0) {
        this.curEditList.push(["code", curEditData.code]);
      }
      if (curEditData.message) {
        this.curEditList.push(["message", curEditData.message]);
      }
      // 按表头顺序排序
      this.columns.forEach(item => {
        this.curEditList.push([item, curEditData[item]]);
      });
      this.curEditIndex = index;
      this.editVisible = true;
    },
    // 删除解析结果
    handleDeleteResult(row) {
      this.$confirm("确定删除本条数据？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        closeOnPressEscape: false,
        type: "info"
      })
        .then(() => {
          // 找到数据下标
          const idx = this.analysisLists.indexOf(row);
          if (idx !== -1) {
            this.analysisLists.splice(idx, 1);
            this.excelRowIdMap.delete(row.excelRowId);
            // 处理一下分页逻辑 当前页无数据时返回上一页
            let { pageIndex, pageSize } = this.searchForm;
            if (this.analysisLists.length <= (pageIndex - 1) * pageSize) {
              this.searchForm.pageIndex = pageIndex > 1 ? pageIndex - 1 : 1;
            }
          }

          // 在选中项中也删除
          const selIdx = this.selections.indexOf(row);
          if (selIdx !== -1) {
            this.selections.splice(selIdx, 1);
          }
        })
        .catch(err => {
          printError(err);
        });
    },
    // 确认修改
    handleSaveEditAnalysis() {
      let curEditData = {};
      this.curEditList.forEach(item => {
        curEditData[item[0]] = item[1];
      });
      let rowData = _.cloneDeep(curEditData);
      this.$set(this.analysisLists, this.curEditIndex, rowData);
      // 修改索引对应的值
      this.excelRowIdMap.set(rowData.excelRowId, rowData);
      this.editVisible = false;
      if (this.judgeKey) {
        let judgeKey = this.judgeKey;
        for (let i = this.selections.length - 1; i >= 0; i--) {
          if (this.selections[i][judgeKey] === rowData[judgeKey]) {
            this.$refs.analysisTable.toggleRowSelection(
              this.selections[i],
              false
            );
            this.selections.splice(i, 1);
          }
        }
      } else {
        var index = this.selections.findIndex(item => {
          return item.excelRowId === rowData.excelRowId;
        });
        if (index !== -1) {
          // 删除旧数据，并插入新数据
          this.selections.splice(index, 1, rowData);
          // 设置表格选中新数据
          this.$refs.analysisTable.toggleRowSelection(rowData, true);
        }
      }
    },
    // 表格多选结果改变
    handleSelectionChanged(selections, row) {
      // 存在其他需要一同勾选或取消的数据
      let judgeKey = this.judgeKey;
      let index = this.columns.indexOf(judgeKey);
      if (judgeKey && index >= 0) {
        let sameCheckData = this.analysisLists.filter(item => {
          return row[judgeKey] === item[[judgeKey]];
        });
        let index = sameCheckData.findIndex(item => {
          return item.excelRowId === row.excelRowId;
        });
        // 删除数组中当前数据，联动处理其他关联数据
        if (index >= 0) {
          sameCheckData.splice(index, 1);
        }
        sameCheckData.forEach(row => {
          this.$refs.analysisTable.toggleRowSelection(row);
        });
        // 判断当前是勾选或取消操作
        let rowIndex = selections.findIndex(item => {
          return item.excelRowId === row.excelRowId;
        });
        // 添加选中项的联动项
        if (rowIndex >= 0) {
          selections.push(...sameCheckData);
        } else {
          // 排除取消项的联动数据
          selections = selections.filter(item => {
            let index = sameCheckData.findIndex(x => {
              return x.excelRowId === item.excelRowId;
            });
            return index < 0;
          });
        }
      }
      // 排序操作是用于电子路书需按顺序导入
      selections.sort((a, b) => {
        return a.excelRowId - b.excelRowId;
      });
      this.selections = selections;
    },
    // 全选/取消全选
    handleAllSelectChanged() {
      //selections, node
      let analysisLists = _.cloneDeep(this.analysisLists);
      if (!this.allChecked) {
        //全选
        this.selections = _.cloneDeep(analysisLists);
        analysisLists.forEach(item => {
          this.$refs.analysisTable.toggleRowSelection(item, true);
        });
        this.allChecked = true;
      } else {
        //取消全选
        this.selections = [];
        analysisLists.forEach(item => {
          this.$refs.analysisTable.toggleRowSelection(item, false);
        });
        this.$refs.analysisTable.clearSelection();
        this.allChecked = false;
      }
    },
    // 批量导入选中数据
    handleImport() {
      const { selections } = this;
      if (!selections.length) return this.$message("请选择要导入的数据！");
      // 上传数据
      this.batchImportAnalysisResult(selections);
      // 清除选中的数据
      this.selections = [];
      // 移除表格勾选状态
      this.$refs.analysisTable.clearSelection();
      this.allChecked = false;
    },
    // 返回上一页
    handleGoBack() {
      //关闭当前Tag
      this.closeTag(this.$route);
      this.routerPush({
        name: this.originRouteName,
        params: {
          refresh: true
        }
      });
    },
    /* ***************************
     * 下载模版/上传相关
     * ***************************/
    // 下载模板
    async handleDownloadTemplate() {
      try {
        this.pageLoadingText = "数据正在请求中...";
        this.loading = true;
        // 动态模板调用模板下载接口
        if (this.dynamicTemplate) {
          let dynamicTemplate = JSON.parse(this.dynamicTemplate);
          let res = await getExportTemplate(dynamicTemplate);
          //解析导出信息，并下载
          exportXlsx(res);
        } else {
          // 非动态直接下载文件
          let downloadIframe = document.createElement("iframe");
          downloadIframe.setAttribute("src", this.initInfo.templateUrl || "");
          downloadIframe.setAttribute("style", "display: none");
          document.body.appendChild(downloadIframe);
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 文件上传前回调
    beforeUpload(file) {
      this.fileIsParsed = false;
      if (file && file.name && typeof file.name === "string") {
        let splitArr = file.name.split(".");
        let suffix = splitArr[splitArr.length - 1];
        if (suffix === "xls" || suffix === "xlsx") {
          return true;
        } else {
          this.fileList = [];
          this.$message("只能上传.xls/.xlsx文件");
          return false;
        }
      } else {
        this.fileList = [];
        this.$message("只能上传.xls/.xlsx文件");
        return false;
      }
    },
    // 文件状态改变。添加文件、上传成功和上传失败时都会被调用
    uploadOnChange(file) {
      this.fileList = [file];
    },
    // 上传时回调
    uploadProgress() {
      this.pageLoadingText = "数据正在解析中...";
      this.loading = true;
    },
    analysisXlsx(file) {
      this.handleReadExcel(file.file);
    },
    handleReadExcel(file) {
      const fileReader = new FileReader();
      fileReader.onload = ev => {
        try {
          const fileData = ev.target.result;
          const workbook = XLSX.read(fileData, {
            type: "binary"
          });
          const wsname = workbook.SheetNames[0]; // 取第一张表(也可以取多个sheet)
          // console.log(workbook);
          // 按表格顺序拿到表头
          const headerReg = /^[A-Z]+1{1}$/; // 匹配第一行正则
          let headerlist = [];
          for (let key in workbook.Sheets[wsname]) {
            if (headerReg.test(key)) {
              headerlist.push(workbook.Sheets[wsname][key]);
            }
          }
          const snArr = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            defval: ""
          }); // 生成json表格内容
          this.excelDataToJson(snArr, headerlist);
        } catch (e) {
          console.log(e);
          return false;
        } finally {
          this.loading = false;
        }
      };
      fileReader.readAsBinaryString(file);
    },
    // 表格数据转换
    excelDataToJson(list = [], titleList = []) {
      if (!list || !list.length) {
        this.$message.error("未解析出数据");
        return;
      }
      let titleListRes = [];
      // 用于提供给标题过滤试用
      const forHelpRowData = list[0];
      if (titleList && titleList.length) {
        titleList.map(title => {
          if (forHelpRowData.hasOwnProperty(title.v)) {
            titleListRes.push(title.v + "");
          }
        });
      } else {
        for (let key in forHelpRowData) {
          titleListRes.push(key);
        }
      }

      let res = [];
      list.map(item => {
        let effective = false; // 用于判断数据是否有效 判断标准：任意一个关键字段有值即为有效，空格不算
        for (let key in item) {
          item[key] += ""; // 统一转化成字符串，方便处理
          item[key] = item[key].trim() || null;
          effective = effective || item[key];
        }
        // 有效数据保存
        if (effective) {
          item.excelRowId = res.length + 1;
          res.push(item);
        }
      });
      if (!res.length) {
        this.$message.error("未解析出数据或数据无效");
        return;
      }

      this.isImported = false; // 隐藏导入状态/失败原因列
      this.fileIsParsed = true; // 文件是否被解析
      this.columns = titleListRes; // 显示表格列
      this.searchForm.pageIndex = 1; // 重置分页
      this.analysisLists = res; // 显示表格数据
      this.allChecked = false;
      this.excelRowIdMap = new Map();
      res.forEach(v => this.excelRowIdMap.set(v.excelRowId, v));
      this.resizeListener();
    },
    // 上传成功回调
    uploadSuccess() {},
    // 上传失败回调
    uploadError() {},
    // 解析结果当前行是否可选中(是否可导入)
    isRowSelectable(row) {
      return row.code !== "OK" && row.code !== -1;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";
@import "~@/assets/styles/variables.scss";
.upload-center {
  .body-content {
    padding: 0 20px;
    height: calc(100% - 40px);
    .edit-title-top {
      padding-top: 20px;
    }
    .edit-title-div {
      padding: 20px 0 10px 0;
    }
  }
  .edit-title {
    padding-left: 0;
    border: none;
    background: none;
    .title-tip {
      margin-left: 15px;
      font-size: 14px;
      font-weight: normal;
      color: #909399;
    }
  }
  .uploader-wrapper {
    position: relative;
    /deep/.uploader {
      display: inline-block;
      margin-left: 100px;
      .el-upload-list {
        margin-left: -100px;
      }
    }
    .tips {
      font-size: 12px;
      margin-left: 10px;
      color: #909399;
      position: absolute;
      left: 180px;
      top: 8px;
    }
    .tips_warning {
      color: $--color-status--assist-1;
      margin-left: 10px;
      font-size: 12px;
      display: inline-block;
      position: relative;
      bottom: 8px;
    }
  }
  .upload-tip {
    display: inline-block;
    width: 555px;
    // float: right;
    p {
      font-size: 14px;
      color: #909399;
      line-height: 22px;
    }
    .warning {
      color: $--color-status--assist-1;
    }
  }
  .download-btn {
    position: absolute;
    left: 0;
  }
  .table-wrapper {
    // margin-top: 10px;
    // height: calc(100% - 117px);
    /deep/ .el-table {
      .el-table__header {
        thead th .cell {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .el-button--small {
        padding: 5px 0;
      }
    }
    .import-status {
      display: inline-block;
      padding: 0 10px;
      line-height: 23px;
      border-radius: 3px;
      cursor: pointer;
      color: #ffffff;
      &.success {
        background-color: $--color-status--assist-2;
      }
      &.fail {
        background-color: $--color-status--assist-3;
      }
      &.pending {
        color: #909399;
      }
    }
  }
  /deep/ .edit-dialog {
    .edit-form-wrapper {
      padding: 20px;
      max-height: 450px;
      overflow: auto;
    }
  }
}
.fail-reason-content {
  padding: 0 10px;
  font-size: 12px;
}
</style>
